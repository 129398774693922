import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const SmartClear = ({ data }) => {
  return (
    <Layout>
      <SEO title="SmartClear Note Clearance System" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              SmartClear Note Clearance System
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Efficient On-Floor Clearance And Compliance
            </h3>
            <p className="mb-4">
              The SmartClear Note Clearance System is a competitively priced,
              highly functional EGM clearance system which seamlessly integrates
              into your floor management or back office system for easy capture
              of expected totals, allowing for effortless on-floor
              reconcilliation and discrepancy resolution.
            </p>
            <p className="mb-4">
              Equipped with an ergonomic, slimline trolley, the unit will
              efficiently value count notes while simultaneously off-sorting
              TITO tickets, simplifying the clearance process and creating a
              live audit trail.
            </p>
            <p className="mb-4">
              Clearance data is easily exported via USB back to the floor
              management system, including EBET, IGT and S7000, eliminating data
              entry and key errors while increasing efficiency.
            </p>
            <p className="mb-4">
              Accommodates zoning or banks easily during clearance.
            </p>
            <ul className="mb-4 ml-4 list-disc">
              <li>Ergonomic slimline trolley</li>
              <li>Easy data import and export via USB</li>
              <li>Easy to use touchscreen</li>
              <li>Configured for multi floor management systems</li>
              <li>Ability to accommodate banks or pokie zoning</li>
              <li>Automatically jumps to next EGM, limiting button presses</li>
            </ul>
            <h3 className="mb-6 text-3xl font-bold leading-none">Features</h3>
            <ul className="mb-4 ml-4 list-disc">
              <li>Battery powered</li>
              <li>Two pocket Note Sorter</li>
              <li>Multi-denominations and off sort TITO tickets</li>
              <li>Barcode scanner</li>
              <li>Barcode and report printers</li>
              <li>Supports up to 1000 EGMs</li>
            </ul>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0 md:w-1/3">
          <div className="w-full max-w-xs px-4 mx-auto">
            <Image fluid={data.SmartClear.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

SmartClear.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query SmartClearQuery {
    SmartClear: file(relativePath: { eq: "smartclear.png" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default SmartClear;
